import '../../styles/IOIpopups.css';

import React, {
  useEffect,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {
  connect,
  useDispatch,
  useSelector,
} from 'react-redux';

import { RootState } from '../../app/store';
import useTranslation from '../../components/customHooks/Translations';
import {
  modifyAdvert,
  submitAdvert,
} from '../../features/adverts/advertisementActions';

export const IOIReview = props => {

  const translation = useTranslation();
  const dispatch = useDispatch();

  const submitAdvertCall = (data) => {
    dispatch(submitAdvert(props.reviewAdvertisement) as any);
    props.setReview(false);
    (document.getElementById('searchInput') as HTMLInputElement).value = '';
    document.getElementById('searchInput').focus();
  }

  const modifyAdvertCall = (data) => {
    dispatch(modifyAdvert(props.reviewAdvertisement) as any);
    props.setReview(false);
    (document.getElementById('searchInput') as HTMLInputElement).value = '';
    document.getElementById('searchInput').focus();
  }

  const equitiesList = useSelector((state: RootState) => state.equities.equities);

  const [direction, setDirection] = useState(props.reviewAdvertisement?.direction?.toLowerCase() || 'buy');
  const [equityName, setEquityName] = useState('')

  let advancedDetails = false;

  useEffect(() => {
    console.log(props.reviewAdvertisement)
    const matchingEquity = equitiesList.find(equity => equity.equityId === props.reviewAdvertisement?.securityId);
    setEquityName(matchingEquity?.ric + ' - ' + matchingEquity?.name);
  }, [props.reviewAdvertisement])

  return (
    <div className={advancedDetails ? 'IOI-basic-popup-advanced':'IOI-basic-popup'}>
      <div className='IOI-review-top'>{translation.ioiReview}</div>
      <div className='IOI-review-body'>
        <div className={advancedDetails ? 'IOI-main-details-advanced':'IOI-main-details'}>
          <div className='IOI-main-details-group-1'>
            <span className={'IOI-main-details-direction-'+direction}>
              {direction === 'buy' ? translation.buy : translation.sell}
            </span>
            {' '}
            <span className='IOI-main-details-security'>
              {equityName.slice(0, 24)}
            </span>?
          </div>
          <span className='IOI-main-details-amount'>
            {`(${Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              }).format(props.reviewAdvertisement?.usdnotional.toFixed(0))} , ${Number(props.reviewAdvertisement?.shareQuantity.toFixed(0)
              )} ${translation.shares})`}
          </span>
        </div>
        <div className={advancedDetails ? 'IOI-advanced-details': 'hide'} >
            <div className='IOI-review-advertising-trigger IOI-advanced-details-content-div'>
                <span className='IOI-advanced-details-label'>{translation.advertisingTrigger}:</span>
                <span className={'IOI-advanced-details-value-'+direction}>JPY {props.reviewAdvertisement?.advertisingTrigger}</span>
            </div>
            <div className='IOI-cancel-trigger IOI-advanced-details-content-div'>
                <span className='IOI-advanced-details-label'>{translation.cancelTrigger}:</span>
                <span className={'IOI-advanced-details-value-'+direction}>JPY {props.reviewAdvertisement?.knockoutTrigger}</span>
            </div>
            <div className='IOI-index-relative-cancel IOI-advanced-details-content-div'>
                <span className='IOI-advanced-details-label'>{translation.indexRelativeCancel}:</span>
                <span className={'IOI-advanced-details-value-'+direction}>{props.reviewAdvertisement?.indexRelativeKOTrigger}%</span>
            </div>
        </div>
        <div className='IOI-review-buttons'>
            <button className='review-cancel-button' onClick={() => {props.setReview(false)}}>{translation.cancel}</button>
            <button className='review-send-button' autoFocus tabIndex={4} onClick={((props.reviewAdvertisement?.advertisementStatus?.toUpperCase() === 'Unsent'?.toUpperCase() || props.reviewAdvertisement?.advertisementStatus?.toUpperCase() === 'Dead'?.toUpperCase()) ? submitAdvertCall : modifyAdvertCall)}>{(props.reviewAdvertisement?.advertisementStatus?.toUpperCase() === 'Unsent'?.toUpperCase() || props.reviewAdvertisement?.advertisementStatus?.toUpperCase() === 'Dead'?.toUpperCase()) ? translation.send: translation.update}</button>
        </div>
      </div>
    </div>
  )
}

IOIReview.propTypes = {
  setReview: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  // Map the relevant state from the Redux store to props
  return {
    reviewAdvertisement: state.advertisements?.selectedAdvertisement,
  };
};

export default connect(mapStateToProps)(IOIReview)