import PropTypes from 'prop-types';
import useTranslation from "../customHooks/Translations";
import '../../styles/IOIpopups.css'

export const IOIMessagePopup = props => {

  const translation = useTranslation();

  function getMessageString(error) {
    let retVal = '';
    if (error?.error && error?.error.length > 0) {
      retVal = translation[error?.error];
    } 
    if (!retVal || retVal.length <= 0 ) {
      retVal = error?.message;
    }
    if (error?.variables && error?.variables.length > 0) {
      retVal = replacePlaceholders(retVal, error?.variables);
    }
    
    return retVal;
  }

  function replacePlaceholders(translation, values) {
    let result = translation;
    
    values.forEach((value, index) => {
      const placeholder = `%s${index + 1}`; // Using %s1, %s2, ... as placeholders
      const placeholderRegex = new RegExp(placeholder.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
      result = result.replace(placeholderRegex, value);
    });
  
    return result;
  }

  return (
    <div className='IOI-basic-popup' style={props.container === 'minimal' ? {top: '-8%', height: "115%"}: {}}>
      <div className='IOI-connection-declined-top'>{translation.genericErrorMessage}</div>
      <div className='IOI-review-body'>
        <div className='IOI-main-details'>
          {getMessageString(props.error)}
        </div>
        <div className='IOI-review-buttons' style={{marginTop: '5%'}}>
            <button className='connection-declined-button' onClick={() => {props.closeMessage();}}>{translation.ok}</button>
        </div>
      </div>
    </div>
  )
}

IOIMessagePopup.propTypes = {
  error: PropTypes.object.isRequired,
  closeMessage: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired
};

export default IOIMessagePopup