import '../styles/forms.css';
import '../styles/buttons.css';

import {
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import useTranslation from '../components/customHooks/Translations';
import {
  RootState,
  useAppDispatch,
} from '../app/store';
import {
  requestResetPassword,
} from '../features/resetPassword/resetPasswordActions';
import { useState } from 'react';
import PasswordResetEmailSentPopup from '../components/PasswordResetEmailSentPopup';

type Inputs = {
  email: string;
}

function ForgotPasswordScreen(): JSX.Element {
  const state = useSelector((state: RootState) => state.resetPassword);
  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const {
    register,
    trigger,
    formState: { errors },
  }  = useForm<Inputs>({
    reValidateMode: 'onChange',
  });


  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("")

  const handleInputChange = event => {
    setEmail(event.target.value);
  }

  const handleEmailSubmit = event => {
    event.preventDefault()
    dispatch(requestResetPassword({email}))
    setShowPopup(true)
  }
  
  return (
    <div className='reset-password-content'>
  
      <div className='form-container'>
        <form
          className='form'
          style={{
            alignItems: 'center',
            gap: '2rem',
            marginTop: '12rem',
          }}
          onSubmit={handleEmailSubmit}
          
        >
        <div id="Logo_g">
          <img
            id="Logo_ha"
            alt="Authentic Indication"
            src="Logo_ha.png"
            srcSet="Logo_ha.png 1x, Logo_ha@2x.png 2x"
          />
        </div>
        <h4 className='header'>{translation.resetPassword}</h4>
          <div className='form-field' onChange={handleInputChange}>
            <label htmlFor="email">{translation.email}</label>
            <input
              type='email'
              value={email}
              placeholder='mail@domain.com'
              className={errors.email && 'error'}
              {...register('email', {
                required: true,
                onChange() {
                  trigger('email');
                },
              })}
              required
            />
            {errors.email && <p className='error' role="alert">{errors.email.message}</p>}
          </div>
          <div>
            <input
              className='btn'
              value={translation.submitForm}
              type="submit"
              disabled={state.loading}
            />
          </div>
        </form>
      </div>

      {showPopup ?
       <PasswordResetEmailSentPopup message={state.error ? translation.gatewayError : translation.emailSent.replace("[email]", email)} redirect='/login'/>
     : null
      }
    </div>
  );
}

export default ForgotPasswordScreen;
